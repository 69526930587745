import { AutoComplete } from "../components/AutoComplete";
import { StockList } from "../components/StockList";
import trading from "../images/logo_brossco.png";
export const StockOverviewPage = () => {
  return <div>
    <div className="text-center mt-5">
      <img src={trading} alt="Logo Bross Co" />
    </div>
    <div className="text-center mb-5" style={{ color: "#00afef" }}>
      <h4>BROSS Co. d.o.o.</h4>
      <h6>Solutions for your business</h6>
    </div>
    <div className="text-center mb-5" style={{ color: "rgb(79, 89, 102)" }}>
      <h1>The state of the stock market of the company in the world</h1>
    </div>
    <AutoComplete />
    <StockList />
  </div>;
};